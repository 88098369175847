import React, { Component } from "react";
import "./App.css";
import Event from "../../components/Event";

class App extends Component {
  state = { events: [], filter: "", filteredEvents: [] };

  getEvents = () => {
    fetch(
      "https://www.googleapis.com/calendar/v3/calendars/nology.io_5smheaincm2skd1tcmvv7m37d8@group.calendar.google.com/events?key=AIzaSyCvy_mLdV4A3bPoy9u0hDgACQ1K-h3Fn-Q"
    )
      .then(results => {
        return results.json();
      })
      .then(data => {
        let events = data.items;
        let IDIEvents = events.filter(
          event =>
            event.organizer.displayName === "Interpretive Dance Institute"
        );
        let orderedEvents = IDIEvents.sort((a, b) =>
          a.start[Object.keys(a.start)[0]] > b.start[Object.keys(b.start)[0]]
            ? 1
            : -1
        );
        this.setState({ events: orderedEvents, filteredEvents: orderedEvents });
      });
  };

  componentDidMount() {
    this.getEvents();
  }

  filterByDay = day => {
    const filter = this.state.events.filter(event => {
      return event.start.date
        ? new Date(event.start.date).getDay() === day
        : new Date(event.start.dateTime).getDay() === day;
    });
    this.setState({ filteredEvents: filter });
  };

  showAll = () => {
    this.setState({ filteredEvents: this.state.events });
  };
  render() {
    let events = this.state.filteredEvents;
    const allEvents = events
      ? events.map(event => {
          let start = new Date(event.start.dateTime);
          let startTime = start
            ? start.getHours() > 11
              ? start.getHours() > 12
                ? `${start.getHours() - 12}PM`
                : `${start.getHours()}PM`
              : `${start.getHours()}AM`
            : null;
          let end = new Date(event.end.dateTime);
          let endTime =
            end.getHours() > 11
              ? end.getHours() >= 12
                ? `${end.getHours() - 12}PM`
                : `${end.getHours()}PM`
              : `${end.getHours()}AM`;

          let date = event.start.date
            ? new Date(event.start.date).toDateString()
            : `${start.toDateString()} ${startTime} - ${endTime}`;

          let recurring =
            event.recurrence != null
              ? event.recurrence.toString().includes("WEEKLY")
                ? "Weekly event"
                : "Monthly event"
              : null;

          return (
            <Event
              key={event.id}
              summary={event.summary}
              date={date}
              recurrence={recurring}
            />
          );
        })
      : null;
    const buttons = [
      { day: "Monday", number: 1 },
      { day: "Tuesday", number: 2 },
      { day: "Wednesday", number: 3 },
      { day: "Thursday", number: 4 },
      { day: "Friday", number: 5 },
      { day: "Saturday", number: 6 },
      { day: "Sunday", number: 0 }
    ];
    return (
      <div className="App">
        <header className="header">
          <h1>Interpretive Dance Institute Calendar</h1>
        </header>
        <div className="container">
          <div className="buttons">
            {buttons.map((button, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    this.filterByDay(button.number);
                  }}
                >
                  {button.day}
                </button>
              );
            })}
            <button onClick={this.showAll}>All</button>
          </div>
          <div className="itemContainer">{allEvents}</div>
        </div>
      </div>
    );
  }
}

export default App;
