import React, { Component } from "react";

class Event extends Component {
  state = {};
  render() {
    return (
      <div className="item">
        <h2 className="start">{this.props.date}</h2>
        <h3>{this.props.summary}</h3>
        <p>{this.props.recurrence}</p>
      </div>
    );
  }
}

export default Event;
